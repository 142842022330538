import styled from 'styled-components'
import { Card, CardProps } from '@pancakeswap/uikit'
import { StyledCard, StyledCardInner } from '@pancakeswap/uikit/src/components/Card/StyledCard'

export const BodyWrapper = styled(Card)`
  border-radius: 0;
  max-width: 480px;
  width: 100%;
  z-index: 1;
  background: transparent;
  padding: 0;
  ${StyledCardInner} {
    background: transparent;
    border-radius: 0;
  }
` 

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...cardProps }: { children: React.ReactNode } & CardProps) {
  return <BodyWrapper {...cardProps}>{children}</BodyWrapper>
}
